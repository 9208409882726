$primary-color: #FFC107;
$secondary-color: #282828;
$featured-image: url(/images/header.png);

$header-background:#FFFFFF;

$page-header-bkg:#f9f9f9;
$page-header:$secondary-color;

$hero-bkg:#EFEFEF;
$hero-h1-color:#FFFFFF;
$hero-button-bkg:$primary-color;
$hero-button-text:white;

$toggle-icon-bar:white;
$line-color:#dddddd;

$signup-bkg: #EFEFEF;
$signup-text:$secondary-color;
$signup-button-bkg:$primary-color;
$signup-button-text:white;


/* header and navbar */
header{
  background-color: $header-background;
  border-top:5px solid $primary-color;
}

.navbar{
  margin-bottom:0;
  min-height:70px;
}
.navbar-brand {
  padding: 0;
  img {
    padding-top:15px;
    max-width:100%;
  }
}
@media screen and (max-width: 1000px){
  .navbar-brand img{
    max-width:220px;
  }
  .navbar{
    min-height:50px;
  }
}
@media screen and (max-width: 430px) {
  .navbar-brand img {
    max-width:210px;
  }
  .navbar {
    min-height:50px;
  }
}
.navbar-nav>li>a {
  padding-top:20px;
  color: $primary-color;
  font-family:"Oswald",sans-serif;
  text-transform:uppercase !important;
}
.nav>li>a:focus, .nav>li>a:hover{
  background-color : transparent;
}
.navbar-toggle {
  background-color:$primary-color;
  margin-right:0;

  .icon-bar {
    background-color: $toggle-icon-bar;
  }
}

/* body, main and basic styles */
body{
  color:#666;
  font:16px "Open Sans", sans-serif;
  line-height: 23px;
  word-wrap: break-word;
  font-weight: 300;
}
a{
  color:$secondary-color;
}
a:hover, a:active{
  color:$primary-color;
}
h1, h2, h3, h4, h5, h6{
  color : $secondary-color;
  margin: 0;
  line-height: 1.2;
  font-family: 'Oswald', sans-serif;
}

p {
  margin:0 0 20px;
}
input[type="text"], input[type="email"], input[type="search"], input[type="password"], input[type="tel"], input[type="url"], input[type="date"], textarea{
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  width: 100%;
  margin: 0 0 20px;
  background-color: #f9f9f9;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
input[type="reset"], input[type="button"], input[type="submit"]{
  background-color: $primary-color;
  border:1px solid $primary-color;
  color: #FFF;
  font-weight: 700;
  cursor: pointer;
  margin-bottom: 30px;
  text-transform: uppercase;
  display: block;
  text-align: center;
  height: 40px;
  padding: 0 20px;
  margin-top: 5px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
main {
  padding:30px 0 0;
  h3{
    font-weight:700;
    text-transform: uppercase;
  }
  h3.contact{
    margin-top:0;
  }
  h3.post-title{
    margin-bottom:20px;
  }
}
.content h2{
  text-align: left;
  margin-top:20px;
}
main .pagination{
  margin: 20px 0 60px;
  padding-bottom: 6px;
  border-radius: 0;
  border-bottom: 2px solid $primary-color;
}
main h2.pagetitle{
  margin-bottom:40px;
}
/* footer */
footer{
  background-color:#000;
  border-top:1px solid #3C3C3C;
  padding: 30px 0px;
  color:#FFF;
  font-size: 14px;
  line-height: 21px;
  text-align:center;
  margin:auto;
  .container{
    max-width: 85%;
  }
  a{
    display:none;
  }
}
.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  a {
    display:block;
    background-color: $secondary-color;
    color: #FFF;
    width: 40px;
    height: 40px;
    border-radius: 3px;
    font-size: 16px;
    line-height: 40px;
    text-align: center;
  }
}
@media screen and (max-width:830px) {
  footer .copyright{
    padding-bottom: 10px;
    display: block;
  }
}
/*section specific code */
/*styles for page-header partial*/
section.page-title-wrap {
  background-color: $page-header-bkg !important;
  padding: 20px 15px 20px;
  h1.page-title {
    text-align: left;
    max-width:1170px;
    font-size: 28px;
    line-height: 35px;
    color: $page-header;
    font-family:'Oswald', sans-serif;
    margin:0 auto;
    text-transform: uppercase;
  }
}
/* blog */
.post{
  margin-bottom:40px;
  .entry-meta{
    float: left;
    width: 65px;
    margin-right:25px;
    margin-bottom:50px;
    text-align: right;
    font-family: 'Oswald', sans-serif;
    .entry-date{
      display: block;
      font-size: 0.9em;
      margin-bottom: 20px;
      .day{
        color:$primary-color;
        font-size: 60px;
        line-height: 1.3;
      }
      .month-year{
        display: block;
        text-transform: uppercase;
      }
    }
  }
  .post-wrapper{
    padding-left:105px;
    figure{
      background: #f9f9f9;
      text-align: center;
      border-bottom: 5px solid #FFC107;
      margin-bottom:15px;
      img{
        max-width:100%;
      }
    }
    header{
      border-top:0;
      text-transform: uppercase;
      margin-bottom: 15px;
    }
    .entry-readmore{
      margin-top: 20px;
      a{
        background: #FFC107;
        padding: 0 20px;
        line-height: 40px;
        color: #FFF;
        border: 0;
        -moz-transition: all 0.3s ease;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
        display: inline-block;
      }
    }
  }
}

/* sidebar */
.side{
  h3.widget-title{
    position: relative;
    padding-bottom: 10px;
    margin-bottom: 20px;
    text-transform: uppercase;
  }
  .widget-title:after{
    content: "";
    position: absolute;
    left: 0;
    top: 100%;
    width: 30px;
    background: #FFC107;
    height: 2px;
  }
  ul.by-industry{
    list-style: none;
    padding: 0;
    margin: 0 0 30px;
    li{
      padding: 10px 0 5px;
      border-bottom: 1px solid #f9f9f9;
    }
  }
}
/* contact page */
.field{
  .label{
    display: none;
  }
  .control{
    margin-bottom:0;
  }
}
/*hero only needed when including the hero partial*/

section.hero {
  text-align:center;
  background-color: #ff5b50;
  background-image: $featured-image;
  padding: 8% 0;
  min-height: 460px;
  background-size: cover;
  background-position:center bottom;
  background-repeat: no-repeat;
  .hero_message {
    text-align: center;
    margin: auto;
    padding: 1%;
    width: 100%;
    h1, p {
      display:none;
    }
    a.read-more {
      display:block;
      font-family:'Oswald', sans-serif;
      background-color:$hero-button-bkg;
      color:$hero-button-text;
      text-transform: uppercase;
      text-shadow:1px 1px 1px rgba(0,0,0,0.2);
      max-width: 200px;
      margin: auto;
      text-align: center;
      border-radius: 0;
      box-shadow: 1px 1px 5px rgba(0,0,0,0.2);
      font-size: 2.2rem;
    }
  }
}
@media screen and (max-width: 845px){
  section.hero{
    min-height:300px;
  }
}
@media screen and (max-width: 485px){
  section.hero{
    min-height:100px;
    background-image:none;
  }
}
/*fast growth*/
section.fast-growth{
  padding:60px 0;
  h2{
    text-align:left;
    margin-bottom: 20px;
  }
  p{
    margin-bottom:40px;
    a{
      color:$primary-color;
    }
  }
  .about-image{
    text-align:center;
    margin-top:60px;
    img {
      max-width: 90%;
    }
  }
  .progress {
    background: transparent;
    height:auto;
    border:0;
    -webkit-box-shadow: none;
    box-shadow: none;
    h6 {
      font-weight: 300;
      margin-bottom: 5px;
      font-size: 17px;
    }
    .progress-bar {
      height: 8px;
      width: 100%;
      position: relative;
      line-height: 20px;
      font-size: 13px;
      background: #f6f6f6;
      .progress-bar-length {
        background: #FFC107;
        height: 100%;
        text-align: right;
        padding-right: 20px;
        span{
          position: absolute;
          right: 0;
          bottom: 100%;
          color: #666;
          font-size: 12px;
        }
      }
    }
  }
}
/* popular opportunities */
section.popular{
  padding:60px 0;
  background-color:$primary-color;
  h2{
    color:#FFF;
  }
  .featured-post{
    float: left;
    margin-left: 3%;
    width: 30.33%;
    text-align: center;
    border: 2px solid #FFF;
    padding: 30px;
    .featured-icon{
      display: inline-block;
      color: #FFF;
      font-size: 46px;
      line-height: 80px;
      width: 180px;
    }
    h5{
      text-transform: uppercase;
      margin-bottom: 25px;
      font-size:20px;
      color:#fff;
    }
    .featured-link {
      -ms-transform: translateY(52px);
      -webkit-transform: translateY(52px);
      transform: translateY(52px);
      a {
        padding: 0 15px;
        display: inline-block;
        line-height: 40px;
        background: #FFF;
        color: #FFC107;
        border-right: 10px solid #FFC107;
        border-left: 10px solid #FFC107;
      }
    }
  }
}
@media screen and (max-width:744px){
  section.popular .featured-post{
    width:93%;
    margin-bottom:40px;
  }
}
/* Growing Opportunities */
section.growing{
  padding:0;
  background-image:url(/images/section-bkg.png);
  background-size:cover;
  background-repeat:no-repeat;
  .overlay{
    padding:60px 0;
    background-color:rgba(0,0,0,.2);
  }
  h2{
    margin-bottom:20px;
    color:#fff;
  }
  p{
    margin:0 auto 60px;
    color:#FFFFFF;
    max-width: 700px;
    text-align:center;
    a{
      color:$primary-color;
    }
  }
  .counter{
    float: left;
    width: 20%;
    margin-left: 5%;
    text-align: center;
    border: 2px solid #FFC107;
    border-top: 0;
    padding: 0 20px 20px;
    position: relative;
    .counter-icon{
      display: inline-block;
      font-size: 36px;
      color: #FFC107;
      position: relative;
      -ms-transform: translate(0, -17px);
      -webkit-transform: translate(0, -17px);
      transform: translate(0, -17px);
      line-height: 36px;
    }
    .odometer{
      font-size: 48px;
      line-height: 1;
      margin-bottom: 25px;
      letter-spacing: 3px;
      font-family: 'Oswald', sans-serif;
      font-weight: 400;
      color:#FFF;
    }
    .counter-title{
      letter-spacing: 1px;
      text-transform: uppercase;
      font-weight: 400;
      margin: 15px 0 0 0;
      font-size: 17px;
      a{
        color:$primary-color;
      }
    }
  }
  .counter:before{
    content: "";
    position: absolute;
    width: 30%;
    right: 0;
    top: 0;
    height: 2px;
    background: #FFC107;
  }
  .counter:after{
    content: "";
    position: absolute;
    width: 30%;
    left: 0;
    top: 0;
    height: 2px;
    background: #FFC107;
  }
}
@media screen and (max-width:744px){
  section.growing .counter{
    width:90%;
    margin-bottom:40px;
  }
}
/* winning advice home blog*/
section.winning{
  padding:60px 0;
  background-color:#f6f6f6;
 .blog-post{
    float: left;
    margin-left: 3%;
    width: 30.333%;
    background: #FFF;
    margin-bottom: 30px;
    border-bottom: 6px solid #FFC107;
    padding:0;
    .blog-thumbnail a{
      display: block;
      position: relative;
      img{
        height: auto;
        max-width: 100%;
        vertical-align: middle;
      }
    }
   .blog-excerpt {
     padding: 20px;
     h5 {
       font-weight: 400;
       font-size: 20px;
       margin-bottom: 15px;
       font-family: "Oswald", sans-serif;
       text-transform: uppercase;
     }
     .blog-date {
       font-size: 14px;
       color: #999;
       margin-bottom: 10px;
       .fa{
         padding-right:8px;
       }
     }
   }
   .blog-read-more{
     padding: 0 20px 20px;
     text-align: right;
     a{
       display: inline-block;
       background: #FFC107;
       color: #FFF;
       padding: 0 15px;
       line-height: 38px;
       text-align: center;
       font-size: 16px;
       border-radius: 1px;
     }
   }
 }
}
@media screen and (max-width:744px){
  section.winning .blog-post{
    width:93%;
    margin-bottom:40px;
    .blog-thumbnail{
      text-align:center;
    }
  }
}
/* sections */
section{
  h2{
    text-align:center;
    text-transform: uppercase;
    font-family:'Oswald', sans-serif;
    margin-bottom:40px;
  }
}

/* home search */
section#home-search{
  background-image:url(/images/section-bkg.png);
  background-size:cover;
  background-repeat:no-repeat;
  text-align:center;
  margin:auto;
  .overlay{
    padding:60px 0;
    background-color:rgba(0,0,0,.2)
  }
  h2{
    color:#FFF;
    text-transform: uppercase;
    font-family:'Oswald', sans-serif;
    margin-bottom:40px;
  }
  .column{
    max-width:1150px;
    text-align:center;
    margin:auto;
    input[type="text"]{
      width: 35%;
    }
    input[type="submit"]{
      display: inline-block;
      height:44px;
      border:2px solid $primary-color;
    }
  }
}
@media screen and (max-width: 744px){
  section#home-search {
    .column input[type="text"] {
      width: 90%;
    }
  }
}

/* site specific before_footer*/
.before-footer{
  border-top:6px solid $primary-color;
  background-color:$secondary-color;
  color:#BBB;
  padding:30px 0 30px;
  font-size:12px;
  text-align: center;
  margin:0 auto ;
  a{
    color:#fff;
    font-weight:400;
    font-size:13px;
  }
}

