/* header and navbar */
header {
  background-color: #FFFFFF;
  border-top: 5px solid #FFC107;
}

.navbar {
  margin-bottom: 0;
  min-height: 70px;
}

.navbar-brand {
  padding: 0;
}

.navbar-brand img {
  padding-top: 15px;
  max-width: 100%;
}

@media screen and (max-width: 1000px) {
  .navbar-brand img {
    max-width: 220px;
  }
  .navbar {
    min-height: 50px;
  }
}

@media screen and (max-width: 430px) {
  .navbar-brand img {
    max-width: 210px;
  }
  .navbar {
    min-height: 50px;
  }
}

.navbar-nav > li > a {
  padding-top: 20px;
  color: #FFC107;
  font-family: "Oswald",sans-serif;
  text-transform: uppercase !important;
}

.nav > li > a:focus, .nav > li > a:hover {
  background-color: transparent;
}

.navbar-toggle {
  background-color: #FFC107;
  margin-right: 0;
}

.navbar-toggle .icon-bar {
  background-color: white;
}

/* body, main and basic styles */
body {
  color: #666;
  font: 16px "Open Sans", sans-serif;
  line-height: 23px;
  word-wrap: break-word;
  font-weight: 300;
}

a {
  color: #282828;
}

a:hover, a:active {
  color: #FFC107;
}

h1, h2, h3, h4, h5, h6 {
  color: #282828;
  margin: 0;
  line-height: 1.2;
  font-family: 'Oswald', sans-serif;
}

p {
  margin: 0 0 20px;
}

input[type="text"], input[type="email"], input[type="search"], input[type="password"], input[type="tel"], input[type="url"], input[type="date"], textarea {
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  width: 100%;
  margin: 0 0 20px;
  background-color: #f9f9f9;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

input[type="reset"], input[type="button"], input[type="submit"] {
  background-color: #FFC107;
  border: 1px solid #FFC107;
  color: #FFF;
  font-weight: 700;
  cursor: pointer;
  margin-bottom: 30px;
  text-transform: uppercase;
  display: block;
  text-align: center;
  height: 40px;
  padding: 0 20px;
  margin-top: 5px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

main {
  padding: 30px 0 0;
}

main h3 {
  font-weight: 700;
  text-transform: uppercase;
}

main h3.contact {
  margin-top: 0;
}

main h3.post-title {
  margin-bottom: 20px;
}

.content h2 {
  text-align: left;
  margin-top: 20px;
}

main .pagination {
  margin: 20px 0 60px;
  padding-bottom: 6px;
  border-radius: 0;
  border-bottom: 2px solid #FFC107;
}

main h2.pagetitle {
  margin-bottom: 40px;
}

/* footer */
footer {
  background-color: #000;
  border-top: 1px solid #3C3C3C;
  padding: 30px 0px;
  color: #FFF;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  margin: auto;
}

footer .container {
  max-width: 85%;
}

footer a {
  display: none;
}

.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.back-to-top a {
  display: block;
  background-color: #282828;
  color: #FFF;
  width: 40px;
  height: 40px;
  border-radius: 3px;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
}

@media screen and (max-width: 830px) {
  footer .copyright {
    padding-bottom: 10px;
    display: block;
  }
}

/*section specific code */
/*styles for page-header partial*/
section.page-title-wrap {
  background-color: #f9f9f9 !important;
  padding: 20px 15px 20px;
}

section.page-title-wrap h1.page-title {
  text-align: left;
  max-width: 1170px;
  font-size: 28px;
  line-height: 35px;
  color: #282828;
  font-family: 'Oswald', sans-serif;
  margin: 0 auto;
  text-transform: uppercase;
}

/* blog */
.post {
  margin-bottom: 40px;
}

.post .entry-meta {
  float: left;
  width: 65px;
  margin-right: 25px;
  margin-bottom: 50px;
  text-align: right;
  font-family: 'Oswald', sans-serif;
}

.post .entry-meta .entry-date {
  display: block;
  font-size: 0.9em;
  margin-bottom: 20px;
}

.post .entry-meta .entry-date .day {
  color: #FFC107;
  font-size: 60px;
  line-height: 1.3;
}

.post .entry-meta .entry-date .month-year {
  display: block;
  text-transform: uppercase;
}

.post .post-wrapper {
  padding-left: 105px;
}

.post .post-wrapper figure {
  background: #f9f9f9;
  text-align: center;
  border-bottom: 5px solid #FFC107;
  margin-bottom: 15px;
}

.post .post-wrapper figure img {
  max-width: 100%;
}

.post .post-wrapper header {
  border-top: 0;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.post .post-wrapper .entry-readmore {
  margin-top: 20px;
}

.post .post-wrapper .entry-readmore a {
  background: #FFC107;
  padding: 0 20px;
  line-height: 40px;
  color: #FFF;
  border: 0;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  display: inline-block;
}

/* sidebar */
.side h3.widget-title {
  position: relative;
  padding-bottom: 10px;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.side .widget-title:after {
  content: "";
  position: absolute;
  left: 0;
  top: 100%;
  width: 30px;
  background: #FFC107;
  height: 2px;
}

.side ul.by-industry {
  list-style: none;
  padding: 0;
  margin: 0 0 30px;
}

.side ul.by-industry li {
  padding: 10px 0 5px;
  border-bottom: 1px solid #f9f9f9;
}

/* contact page */
.field .label {
  display: none;
}

.field .control {
  margin-bottom: 0;
}

/*hero only needed when including the hero partial*/
section.hero {
  text-align: center;
  background-color: #ff5b50;
  background-image: url(/images/header.png);
  padding: 8% 0;
  min-height: 460px;
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat;
}

section.hero .hero_message {
  text-align: center;
  margin: auto;
  padding: 1%;
  width: 100%;
}

section.hero .hero_message h1, section.hero .hero_message p {
  display: none;
}

section.hero .hero_message a.read-more {
  display: block;
  font-family: 'Oswald', sans-serif;
  background-color: #FFC107;
  color: white;
  text-transform: uppercase;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  max-width: 200px;
  margin: auto;
  text-align: center;
  border-radius: 0;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
  font-size: 2.2rem;
}

@media screen and (max-width: 845px) {
  section.hero {
    min-height: 300px;
  }
}

@media screen and (max-width: 485px) {
  section.hero {
    min-height: 100px;
    background-image: none;
  }
}

/*fast growth*/
section.fast-growth {
  padding: 60px 0;
}

section.fast-growth h2 {
  text-align: left;
  margin-bottom: 20px;
}

section.fast-growth p {
  margin-bottom: 40px;
}

section.fast-growth p a {
  color: #FFC107;
}

section.fast-growth .about-image {
  text-align: center;
  margin-top: 60px;
}

section.fast-growth .about-image img {
  max-width: 90%;
}

section.fast-growth .progress {
  background: transparent;
  height: auto;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

section.fast-growth .progress h6 {
  font-weight: 300;
  margin-bottom: 5px;
  font-size: 17px;
}

section.fast-growth .progress .progress-bar {
  height: 8px;
  width: 100%;
  position: relative;
  line-height: 20px;
  font-size: 13px;
  background: #f6f6f6;
}

section.fast-growth .progress .progress-bar .progress-bar-length {
  background: #FFC107;
  height: 100%;
  text-align: right;
  padding-right: 20px;
}

section.fast-growth .progress .progress-bar .progress-bar-length span {
  position: absolute;
  right: 0;
  bottom: 100%;
  color: #666;
  font-size: 12px;
}

/* popular opportunities */
section.popular {
  padding: 60px 0;
  background-color: #FFC107;
}

section.popular h2 {
  color: #FFF;
}

section.popular .featured-post {
  float: left;
  margin-left: 3%;
  width: 30.33%;
  text-align: center;
  border: 2px solid #FFF;
  padding: 30px;
}

section.popular .featured-post .featured-icon {
  display: inline-block;
  color: #FFF;
  font-size: 46px;
  line-height: 80px;
  width: 180px;
}

section.popular .featured-post h5 {
  text-transform: uppercase;
  margin-bottom: 25px;
  font-size: 20px;
  color: #fff;
}

section.popular .featured-post .featured-link {
  -ms-transform: translateY(52px);
  -webkit-transform: translateY(52px);
  transform: translateY(52px);
}

section.popular .featured-post .featured-link a {
  padding: 0 15px;
  display: inline-block;
  line-height: 40px;
  background: #FFF;
  color: #FFC107;
  border-right: 10px solid #FFC107;
  border-left: 10px solid #FFC107;
}

@media screen and (max-width: 744px) {
  section.popular .featured-post {
    width: 93%;
    margin-bottom: 40px;
  }
}

/* Growing Opportunities */
section.growing {
  padding: 0;
  background-image: url(/images/section-bkg.png);
  background-size: cover;
  background-repeat: no-repeat;
}

section.growing .overlay {
  padding: 60px 0;
  background-color: rgba(0, 0, 0, 0.2);
}

section.growing h2 {
  margin-bottom: 20px;
  color: #fff;
}

section.growing p {
  margin: 0 auto 60px;
  color: #FFFFFF;
  max-width: 700px;
  text-align: center;
}

section.growing p a {
  color: #FFC107;
}

section.growing .counter {
  float: left;
  width: 20%;
  margin-left: 5%;
  text-align: center;
  border: 2px solid #FFC107;
  border-top: 0;
  padding: 0 20px 20px;
  position: relative;
}

section.growing .counter .counter-icon {
  display: inline-block;
  font-size: 36px;
  color: #FFC107;
  position: relative;
  -ms-transform: translate(0, -17px);
  -webkit-transform: translate(0, -17px);
  transform: translate(0, -17px);
  line-height: 36px;
}

section.growing .counter .odometer {
  font-size: 48px;
  line-height: 1;
  margin-bottom: 25px;
  letter-spacing: 3px;
  font-family: 'Oswald', sans-serif;
  font-weight: 400;
  color: #FFF;
}

section.growing .counter .counter-title {
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 400;
  margin: 15px 0 0 0;
  font-size: 17px;
}

section.growing .counter .counter-title a {
  color: #FFC107;
}

section.growing .counter:before {
  content: "";
  position: absolute;
  width: 30%;
  right: 0;
  top: 0;
  height: 2px;
  background: #FFC107;
}

section.growing .counter:after {
  content: "";
  position: absolute;
  width: 30%;
  left: 0;
  top: 0;
  height: 2px;
  background: #FFC107;
}

@media screen and (max-width: 744px) {
  section.growing .counter {
    width: 90%;
    margin-bottom: 40px;
  }
}

/* winning advice home blog*/
section.winning {
  padding: 60px 0;
  background-color: #f6f6f6;
}

section.winning .blog-post {
  float: left;
  margin-left: 3%;
  width: 30.333%;
  background: #FFF;
  margin-bottom: 30px;
  border-bottom: 6px solid #FFC107;
  padding: 0;
}

section.winning .blog-post .blog-thumbnail a {
  display: block;
  position: relative;
}

section.winning .blog-post .blog-thumbnail a img {
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}

section.winning .blog-post .blog-excerpt {
  padding: 20px;
}

section.winning .blog-post .blog-excerpt h5 {
  font-weight: 400;
  font-size: 20px;
  margin-bottom: 15px;
  font-family: "Oswald", sans-serif;
  text-transform: uppercase;
}

section.winning .blog-post .blog-excerpt .blog-date {
  font-size: 14px;
  color: #999;
  margin-bottom: 10px;
}

section.winning .blog-post .blog-excerpt .blog-date .fa {
  padding-right: 8px;
}

section.winning .blog-post .blog-read-more {
  padding: 0 20px 20px;
  text-align: right;
}

section.winning .blog-post .blog-read-more a {
  display: inline-block;
  background: #FFC107;
  color: #FFF;
  padding: 0 15px;
  line-height: 38px;
  text-align: center;
  font-size: 16px;
  border-radius: 1px;
}

@media screen and (max-width: 744px) {
  section.winning .blog-post {
    width: 93%;
    margin-bottom: 40px;
  }
  section.winning .blog-post .blog-thumbnail {
    text-align: center;
  }
}

/* sections */
section h2 {
  text-align: center;
  text-transform: uppercase;
  font-family: 'Oswald', sans-serif;
  margin-bottom: 40px;
}

/* home search */
section#home-search {
  background-image: url(/images/section-bkg.png);
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  margin: auto;
}

section#home-search .overlay {
  padding: 60px 0;
  background-color: rgba(0, 0, 0, 0.2);
}

section#home-search h2 {
  color: #FFF;
  text-transform: uppercase;
  font-family: 'Oswald', sans-serif;
  margin-bottom: 40px;
}

section#home-search .column {
  max-width: 1150px;
  text-align: center;
  margin: auto;
}

section#home-search .column input[type="text"] {
  width: 35%;
}

section#home-search .column input[type="submit"] {
  display: inline-block;
  height: 44px;
  border: 2px solid #FFC107;
}

@media screen and (max-width: 744px) {
  section#home-search .column input[type="text"] {
    width: 90%;
  }
}

/* site specific before_footer*/
.before-footer {
  border-top: 6px solid #FFC107;
  background-color: #282828;
  color: #BBB;
  padding: 30px 0 30px;
  font-size: 12px;
  text-align: center;
  margin: 0 auto;
}

.before-footer a {
  color: #fff;
  font-weight: 400;
  font-size: 13px;
}
